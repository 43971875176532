import React from 'react';

 const ProductBuilderIcon = (props) => {

  const {
    item
  } = props

  let iconClass = ''

  if(item === 'fixture') iconClass = 'flaticon-lamp-10'
  if(item === 'lampType') iconClass = 'flaticon-light-bulb-5'
  if(item === 'lens') iconClass = 'flaticon-warning'
  if(item === 'guard') iconClass = 'flaticon-light-bulb-6'
  if(item === 'mounting') iconClass = 'flaticon-lamppost'
  if(item === 'color') iconClass = 'ccn-flaticon-photos'

  // alert(item)

  return (
    <><i className={iconClass}></i></>
  )

}

export default ProductBuilderIcon;
